<template>
    <div class="PromotionTwo">
        <div class="PromotionTwoHeader">促销商品：请选择参加活动的商品</div>
        <div class="PromotionTwoTable">
            <el-table :data="tableData"
                      ref="multipleTable"
                      style="width: 100%;flex: 1;margin-top: 30px;border: 1px solid #DCDCDC"
                      :cell-style="{height: '100px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}"
                      :header-cell-style="{fontWeight: '500', color: '#333333', background: '#F5F5F5',height: '46px'}"
                      @selection-change="handleSelectionGoods">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="商品名称" prop="name" align="left">
                    <template slot-scope="scope">
                        <div class="shopData">
                            <img :src="scope.row.image" alt="">
                            <span class="text-overflow-2">{{scope.row.name}} <b>在售</b></span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="商品价格" prop="price" align="center"></el-table-column>
                <el-table-column label="库存" prop="inventory" align="center"></el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <div class="footerLeft">
                <el-button class="btn" @click="toBack">上一步</el-button>
                <el-button class="btn" @click="toNext" v-if="selectGoodsList.length > 0">提交</el-button>
                <el-button class="btn" @click="toNext" disabled v-if="selectGoodsList.length === 0">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "underscore";
    import { mapActions } from 'vuex';
    export default {
        name: "CouponTwo",
        data(){
            return {
                tableData:[],
                man: null,
                jian: null,
                editId: Number(this.$route.query.id) || 0,
                couponNme: '',
                selectGoodsList: [],
            }
        },
        created() {
            if (this.editId) {
                this.getEditGoods().then((res) => {
                    this.getGoodsList(res);
                });
            }
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            }
        },
        mounted() {
            //取缓存 this.$lockr.get('oneStepData')
            this.man = this.$lockr.get('oneStepData').man;
            this.jian = this.$lockr.get('oneStepData').jian;
            this.couponNme = this.$lockr.get('oneStepData').name;
            this.handleSelectionGoods(this.selectGoodsList);
            this.getGoodsList();
        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //获取已选的商品
            getEditGoods() {
                let _this = this;
                return new Promise(function (resolve, reject) {
                    _this.$http.axiosGetBy(_this.$api.showStuMarket, {id: _this.editId, type: '4'}, (res) => {
                        if (res.code === 200) {
                            let list = [];
                            _.each(res.data.data[0].stu_market_data[0].activity_data.data, (item) => {
                                if (item.goods_data) {
                                    list.push(item.goods_data);
                                }
                            });
                            resolve(list);
                        } else {
                            _this.$message({
                                message: res.msg,
                                duration: 1000,
                                type: 'warning',
                                onClose: function() {
                                    _this.$router.push('/student/operationpromotion/coupon');
                                }
                            });
                        }
                    }, (err) => {
                        reject(err);
                        console.log(err);
                    })
                });

            },
            //获取商品
            getGoodsList(list = []) {
                this.$http.axiosGet(this.$api.showStoreMarket, (res) => {
                    if (res.code === 200) {
                        this.tableData = _.map(res.data.data[0].goods_list, (item) => {
                            return {
                                id: item.id,
                                image: item.image.src,
                                inventory: item.inventory,
                                name: item.name,
                                price: item.price
                            }
                        });
                        let _this = this;
                        this.$nextTick(function(){
                            if (list.length) {
                                _.each(_this.tableData, (item, index) => {
                                    if (_.find(list, {id: item.id})) {
                                        _this.$refs.multipleTable.toggleRowSelection(_this.tableData[index], true);
                                    }
                                });
                            }
                        })
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //选择商品
            handleSelectionGoods(val) {
                this.selectGoodsList = val;
            },
            toBack(){
                this.$router.go(-1)
            },
            toNext(){
                let formData = new FormData();
                formData.append('type', '4');
                if (this.couponNme) {
                    formData.append('name', this.couponNme);
                } else {
                    this.$message.warning('请输入优惠券名称');
                    return false;
                }
                let activityData = {};

                activityData.type = '4';
                activityData.data = [];
                let data_arr = [];
                let goods_data = {};
                console.log(this.selectGoodsList);
                for (let i = 0; i< this.selectGoodsList.length; i++) {
                    let tmp = this.selectGoodsList[i];
                    data_arr.push({goods_data: tmp});
                }
                /*for (let key in this.selectGoodsList) {
                    /!*delete this.selectGoodsList[key].create_time;
                    delete this.selectGoodsList[key].delete_time;*!/
                    let img_url = this.selectGoodsList[key].image.url;
                    console.log(img_url);
                    this.selectGoodsList[key].image = img_url;
                    goods_data = this.selectGoodsList[key];
                    data_arr.push({goods_data});

                }*/
                let activityDetail = `满${this.man}元减${this.jian}`;
                let rule = `${this.man}#${this.jian}`;
                data_arr.push({rule});
                // activityData.data.push(data_arr)
                activityData.data = data_arr
                formData.append('activity_data', JSON.stringify(activityData));
                formData.append('activity_detail', activityDetail);

                this.$http.axiosPost(this.$api.saveStudentMarket, formData, (res) => {
                    if (res.code === 200) {
                        if (this.selectGoodsList.length > 0) {
                            this.$message.success(res.msg);
                            this.$router.push({
                                path: '/student/operationpromotion/coupon'
                            })
                            if (this.$common.isCompetion()) {
                                let menus = this.menus;
                                if (Number(menus[1].children[3].status) !== 2) {
                                    this.operation_updateStatus(1,3,2);
                                }
                            }
                        } else {
                            this.$message.warning('请选择商品');
                        }
                    } else {
                        this.$message.warning('请选择商品');
                    }
                }, (err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    .PromotionTwo{
        padding: 0 20px 20px 20px;
        .PromotionTwoHeader{
            color: #343434;
            font-size: 18px;
        }
        .PromotionTwoTable{
            .shopData{
                display: flex;
                align-items: center;
                /*justify-content: center;*/
                img{
                    width: 48px;
                    height: 48px;
                }
                span{
                    color: #333333;
                    font-size: 14px;
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    b{
                        font-weight: 500;
                        display: inline-block;
                        width: 30px;
                        border: 1px solid #39CD7C;
                        color: #39CD7C;
                        font-size: 10px;
                        /*padding: 3px 6px;*/
                        height: 16px;
                        text-align: center;
                        line-height: 16px;
                    }
                }
            }
        }
        .setSku{
            padding-top: 60px;
            color: #343434;
            font-size: 16px;
            b{
                font-weight: 500;
                color: #FD4446;
            }
        }
        .footer{
            padding-top: 58px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .footerLeft{
                .btn{
                    width: 100px;
                    height: 40px;
                    line-height: 0;
                }
                .btn:nth-child(2){
                    background: #FD4446;
                    border-color: #FD4446;
                    color: #ffffff;
                }
            }
        }
    }
    /deep/ .el-button.is-disabled {
        background-color: #DCDCDC !important;
        border-color: #DCDCDC !important;
    }
</style>